'use strict'
require('@/mixins/DateTimeHelper')
const programConstants = require('@/store/modules/programs/constants.js')

const addGenericCustomClasses = items => {
  for (let i = 0; i < items.length; i++) {
    const column = items[i]
    const sortable = Boolean(column.sortable !== false)
    if (!column.thClass) {
      column.thClass = ''
    }
    if (sortable) {
      column.thClass += ' pointer'
    }
  }
  return items
}
const RespondentList = {
  data () {
    const columns = [{
      label: this.$t('owlId'),
      tdClass: 'id',
      thClass: 'id',
      type: 'number',
      field: 'id'
    }, {
      label: this.$t('respondentsTable.respondent'),
      tdClass: 'name',
      thClass: 'name',
      field: 'name'
    }, {
      label: this.$t('respondentsTable.contact'),
      tdClass: 'contact',
      thClass: 'contact',
      sortable: false,
      field: 'contact'
    }, {
      label: this.$t('respondentsTable.lastActivity'),
      tdClass: 'last_activity',
      thClass: 'last_activity',
      sortable: false,
      field: 'last_activity'
    }, {
      label: this.$t('respondentsTable.relationship'),
      tdClass: 'relationship',
      thClass: 'relationship',
      sortable: false,
      field: 'relationship'
    }, {
      label: this.$t('respondentsTable.patient'),
      tdClass: 'patients',
      thClass: 'patients',
      sortable: false,
      field: 'patients'
    }, {
      label: this.$t('respondentsTable.mrn'),
      tdClass: 'mrn',
      thClass: 'mrn',
      sortable: false,
      field: 'mrn'
    }]
    return {
      columns: addGenericCustomClasses(columns)
    }
  }
}
const PatientsList = {
  computed: {
    columns () {
      const columns = [{
        label: this.$t('patientsTable.patient'),
        field: 'name'
      }, {
        label: this.$t('patientsTable.clinicians'),
        tdClass: 'td-patient-clinicians',
        field: 'clinicians'
      }, {
        label: this.$t('patientsTable.nextVisit'),
        thClass: 'vgt-center-align',
        tdClass: 'vgt-center-align',
        field: 'next_visit'
      }, {
        label: this.$t('patientsTable.nextSessionDue'),
        field: 'latest_session_date',
        thClass: 'vgt-center-align',
        tdClass: 'vgt-center-align',
        width: '140px'
      }, {
        label: this.$t('patientsTable.lastSessionCompleted'),
        field: 'latest_completed_session_date',
        thClass: 'vgt-center-align',
        tdClass: 'text-capitalize vgt-center-align',
        width: '157px'
      }, {
        label: 'id',
        field: 'access_control_id',
        type: 'number',
        hidden: true
      }, {
        label: 'id',
        field: 'mrn',
        type: 'number',
        hidden: true
      }, {
        label: 'age',
        field: 'age',
        type: 'number',
        hidden: true
      }, {
        label: 'justAdded',
        field: 'justAdded',
        hidden: true
      }, {
        label: this.$t('patientsTable.conditions'),
        field: this.conditionsField,
        hidden: true
      }, {
        label: 'teams',
        field: 'teams',
        hidden: true
      }, {
        label: 'preferred_name',
        field: 'preferred_name',
        hidden: true
      }]
      const filteredColumns = (this.$store.getters.generalSettings.include_appointments_feature && this.$store.getters.generalSettings.ehr_integration_available) ? columns : columns.filter(c => c.field !== 'next_visit')
      return addGenericCustomClasses(filteredColumns)
    }
  }
}
const ClinicianAlertTable = {
  computed: {
    columns () {
      const columns = [{
        label: this.$t('time'),
        field: 'time',
        width: '130px',
        tdClass: 'vgt-left-align',
        thClass: 'vgt-left-align'
      }, {
        label: 'respondent_data.name',
        field: 'respondent_data.name',
        hidden: true
      }, {
        label: 'additional_params.endorsed_question_text',
        field: 'additional_params.endorsed_question_text',
        hidden: true
      }, {
        label: 'measure_name',
        field: 'measure_name',
        hidden: true
      }]
      if (this.$store.getters.loggedInUser.user_type === this.userTypeSupervisor) {
        columns.push({
          label: this.$t('clinician_s'),
          field: 'clinicians',
          sortable: false
        })
      }
      columns.push({
        label: this.$t('alert'),
        field: 'content',
        sortable: false
      })
      return addGenericCustomClasses(columns)
    }
  }
}
const CliniciansList = {
  computed: {
    columns () {
      const columns = [{
        label: this.$t('clinicianListTable.clinician'),
        field: 'full_name'
      }]

      if (this.$store.getters.generalSettings.programs_enabled) {
        columns.push({
          label: this.$t('clinicianListTable.teams'),
          field: 'teams',
          width: '350px',
          type: 'number',
          thClass: 'vgt-center-align',
          tdClass: 'vgt-center-align'
        })
      }

      return addGenericCustomClasses(columns)
    }
  }
}
const MeasuresClinician = {
  data () {
    const columns = [{
      label: this.$t('measureLibraryTable.starred'),
      field: 'starred',
      sortable: false,
      width: '42px'
    },
    {
      label: this.$t('measureLibraryTable.name'),
      field: 'name',
      filterable: true,
      width: '350px',
      html: true
    },
    {
      label: this.$t('measureLibraryTable.questions_count'),
      field: 'questions_count',
      sortFn: this.sortAsNumber,
      filterable: true,
      width: '84px',
      thClass: 'vgt-center-align',
      tdClass: 'vgt-center-align'
    },
    {
      label: this.$t('measureLibraryTable.condition'),
      field: 'condition',
      filterable: true,
      width: '172px'
    },
    {
      label: this.$t('measureLibraryTable.respondent'),
      field: 'respondent',
      filterable: true,
      width: '172px'
    },
    {
      label: 'age_range',
      field: 'age_range',
      hidden: true
    }]
    return {
      columns: addGenericCustomClasses(columns)
    }
  }
}
const ClinicianPatientProgram = {
  data () {
    const columns = [{
      label: this.$t('programsTable.program'),
      field: 'name',
      width: 'auto'
    },
    {
      label: this.$t('programsTable.admission'),
      field: 'admission_date',
      width: '140px'
    },
    {
      label: this.$t('programsTable.termination'),
      field: 'termination_date',
      width: '140px'
    },
    {
      field: 'actions',
      width: '140px',
      sortable: false
    }]
    return {
      columns: addGenericCustomClasses(columns)
    }
  }
}

const ClinicianPatientProgramsTable = {
  data () {
    const columns = [{
      label: this.$t('programsTable.program'),
      field: 'name',
      width: 'auto'
    },
    {
      label: this.$t('programsTable.admission'),
      field: 'admission_date',
      width: '140px'
    },
    {
      label: this.$t('programsTable.discharge'),
      field: 'termination_date',
      width: '140px'
    },
    {
      field: 'actions',
      width: '40px',
      sortable: false
    }]
    return {
      columns: addGenericCustomClasses(columns)
    }
  }
}

const ClinicianPrograms = {
  data () {
    const columns = [{
      label: this.$t('programsTable.program'),
      field: 'name',
      width: '400px'
    },
    {
      label: this.$t('programsTable.length'),
      field: 'length',
      width: '150px'
    },
    {
      label: this.$t('programsTable.clinicians'),
      field: 'clinicians',
      sortable: false,
      width: 'auto'
    }]
    return {
      columns: addGenericCustomClasses(columns)
    }
  }
}

const PatientsByProgramTable = {
  data () {
    const columns = this.programId !== programConstants.PATIENTS_NOPROGRAM_ID ? this.getColumnsForPatientWithProgram() : this.getColumnsForPatientWithoutProgram()
    return addGenericCustomClasses(columns)
  },
  methods: {
    getColumnsForPatientWithoutProgram () {
      return {
        columns: [{
          label: this.$t('patientsByProgramTable.patient'),
          field: 'name',
          sortFn: (a, b, c, rowA, rowB) => {
            const rowNameA = rowA.last_name + ' ' + rowA.first_name
            const rowNameB = rowB.last_name + ' ' + rowB.first_name
            return rowNameA.localeCompare(rowNameB)
          }
        }, {
          field: 'mrn',
          hidden: true
        }, {
          field: 'access_control_id',
          label: 'access_control_id',
          hidden: true
        }]
      }
    },
    getColumnsForPatientWithProgram () {
      return {
        columns: [{
          label: this.$t('patientsByProgramTable.patient'),
          field: 'name',
          sortFn: (a, b, c, rowA, rowB) => {
            const rowNameA = rowA.last_name + ' ' + rowA.first_name
            const rowNameB = rowB.last_name + ' ' + rowB.first_name
            return rowNameA.localeCompare(rowNameB)
          }
        },
        {
          label: this.$t('patientsByProgramTable.daysIn'),
          field: 'days_in_program',
          width: '95px',
          type: 'number',
          thClass: 'vgt-center-align',
          tdClass: 'vgt-center-align',
          globalSearchDisabled: true
        },
        {
          label: this.$t('patientsByProgramTable.nextSessionDue'),
          field: 'latest_session_status_string',
          thClass: 'vgt-center-align',
          tdClass: 'vgt-center-align',
          width: '140px',
          sortFn: (a, b, c, rowA, rowB) => {
            return this.getDateFromString(rowB.latest_session_date) - this.getDateFromString(rowA.latest_session_date)
          }
        },
        {
          label: this.$t('patientsByProgramTable.lastSessionCompleted'),
          field: 'latest_completed_session_status_string',
          width: '157px',
          thClass: 'vgt-center-align',
          tdClass: 'vgt-center-align',
          sortFn: (a, b, c, rowA, rowB) => {
            return this.getDateFromString(rowB.latest_completed_session_date) - this.getDateFromString(rowA.latest_completed_session_date)
          }
        },
        {
          label: 'actions',
          field: 'actions',
          width: '45px'
        }, {
          label: 'access_control_id',
          field: 'access_control_id',
          hidden: true
        }, {
          label: 'episode_id',
          field: 'episode_id',
          hidden: true
        }, {
          field: 'mrn',
          hidden: true
        }]
      }
    }
  }
}

const CliniciansTeamList = {
  data () {
    const columns = [{
      label: this.$t('clinicianTeamListTable.team'),
      field: 'name'
    }]
    return {
      columns: addGenericCustomClasses(columns)
    }
  }
}

const ClinicianSchedulesListSingleDay = {
  computed: {
    columns () {
      const staffUser = (this.user.roles && this.user.roles.roles.indexOf(this.userTypeStaff) >= 0)
      const ehr = this.$store.getters.generalSettings.ehr_integration_available
      const columns = [{
        label: this.$t('scheduleListTable.time'),
        field: 'visit_date_time',
        width: '115px'
      }, {
        label: this.$t('scheduleListTable.patient'),
        field: 'patient.full_name',
        width: '300px'
      }, {
        label: staffUser && ehr ? this.$t('scheduleListTable.scheduledWith') : this.$t('scheduleListTable.clinicians'),
        field: 'clinicians'
      }, {
        label: this.$t('scheduleListTable.nextSessionDue'),
        field: 'pending_session.status_string',
        thClass: 'vgt-center-align',
        tdClass: 'vgt-center-align',
        width: '140px'
      }, {
        label: this.$t('scheduleListTable.lastSessionCompleted'),
        field: 'latest_session_data.status_string',
        thClass: 'vgt-center-align',
        tdClass: 'vgt-center-align',
        width: '157px'
      }, {
        label: 'patient.id',
        field: 'patient.id',
        hidden: true
      }, {
        label: this.$t('scheduleListTable.mrn'),
        field: 'patient.mrn',
        hidden: true
      }]
      if (!staffUser) {
        columns.splice(2, 1)
      }
      return addGenericCustomClasses(columns)
    }
  }
}

const PatientsByDueDateColumns = {
  computed: {
    columns () {
      const staffUser = (this.user.roles && this.user.roles.roles.indexOf(this.userTypeStaff) >= 0)
      const ehr = this.$store.getters.generalSettings.ehr_integration_available
      const columns = [{
        label: this.$t('scheduleListTable.patient'),
        field: 'name',
        width: '300px'
      }, {
        label: staffUser && ehr ? this.$t('scheduleListTable.scheduledWith') : this.$t('scheduleListTable.clinicians'),
        field: 'clinicians'
      }, {
        label: this.$t('scheduleListTable.nextSessionDue'),
        field: 'pending_session.status_string',
        thClass: 'vgt-center-align',
        tdClass: 'vgt-center-align',
        width: '140px'
      }, {
        label: this.$t('scheduleListTable.lastSessionCompleted'),
        field: 'latest_session_data.status_string',
        thClass: 'vgt-center-align',
        tdClass: 'vgt-center-align',
        width: '157px'
      }, {
        label: this.$t('scheduleListTable.mrn'),
        field: 'mrn',
        hidden: true
      }, {
        label: 'access_control_id',
        field: 'access_control_id',
        hidden: true
      }]

      if (!staffUser) {
        columns.splice(1, 1)
      }

      return addGenericCustomClasses(columns)
    }
  }
}

const ClinicianPatientMeasuresList = {
  data () {
    const data = {
      columns: [{
        label: 'last_session_status',
        tdClass: 'vgt-center-align last_session_status',
        thClass: 'vgt-center-align',
        field: 'status'
      }, {
        label: this.$t('measuresAssignedTable.due'),
        tdClass: 'due',
        field: 'due_date'
      }, {
        label: this.$t('measuresAssignedTable.completed'),
        tdClass: 'completed',
        field: 'measure_date_completed'
      }, {
        label: this.$t('measuresAssignedTable.pdf'),
        tdClass: 'vgt-center-align pdf-download',
        field: 'pdf_uuid',
        html: true
      }, {
        label: this.$t('measuresAssignedTable.measure'),
        tdClass: 'measure',
        field: 'name'
      }, {
        label: this.$t('measuresAssignedTable.alert'),
        tdClass: 'alert vgt-center-align',
        thClass: 'vgt-center-align',
        field: 'alert_state'
      }, {
        label: this.$t('measuresAssignedTable.frequency'),
        tdClass: 'frequency',
        field: 'frequency.text'
      }, {
        label: this.$t('measuresAssignedTable.visit'),
        tdClass: 'visit',
        field: 'visit_date_time'
      }, {
        label: this.$t('measuresAssignedTable.type'),
        tdClass: 'type',
        field: 'type'
      }, {
        label: this.$t('measuresAssignedTable.program'),
        tdClass: 'program',
        field: 'programs'
      }, {
        label: 'actions',
        field: 'false',
        sortable: false
      }]
    }

    data.columns = data.columns.filter(column => {
      return !((column.field === 'visit_date_time' && ![2, 4].includes(this.$store.getters.generalSettings.setting_state)) || (column.field === 'programs' && !this.$store.getters.generalSettings.programs_enabled))
    })
    data.columns = addGenericCustomClasses(data.columns)
    return data
  }
}

const SettingsLocations = {
  computed: {
    columns () {
      const columns = [{
        label: this.$t('settingsLocationsTable.name'),
        field: 'name'
      }, {
        label: this.$t('settingsLocationsTable.addrress'),
        field: 'address_1'
      }, {
        label: this.$t('settingsLocationsTable.city'),
        field: 'city'
      }, {
        label: this.$t('settingsLocationsTable.state'),
        field: 'state.abbreviation'
      }, {
        label: this.$t('settingsLocationsTable.zip'),
        field: 'zip'
      }, {
        label: 'address_2',
        field: 'address_2',
        hidden: true
      }]
      return addGenericCustomClasses(columns)
    }
  }
}

const SettingsClinicalUsers = {
  computed: {
    columns () {
      const columns = [{
        label: this.$t('settingsClinicalUsersTable.name'),
        field: 'full_name'
      }, {
        label: this.$t('settingsClinicalUsersTable.owlId'),
        field: 'id'
      }, {
        label: this.$t('settingsClinicalUsersTable.status'),
        field: 'statusString'
      }, {
        label: this.$t('settingsClinicalUsersTable.roles'),
        field: 'roleString'
      }, {
        label: this.$t('settingsClinicalUsersTable.locations'),
        field: 'locationString'
      }, {
        label: 'first_name',
        field: 'first_name',
        hidden: true
      }, {
        label: 'roles',
        field: 'roles',
        hidden: true
      }, {
        label: 'locations',
        field: 'locations',
        hidden: true
      }]
      return addGenericCustomClasses(columns)
    }
  }
}

const SettingsPatientUsers = {
  computed: {
    columns () {
      const columns = [{
        label: this.$t('settingsPatientsTable.name'),
        field: 'patient_full_names'
      }, {
        label: 'MRN',
        field: 'mrn'
      }, {
        label: this.$t('settingsPatientsTable.owlId'),
        field: 'id'
      }, {
        label: this.$t('settingsPatientsTable.status'),
        field: 'status'
      }, {
        label: this.$t('settingsPatientsTable.roles'),
        field: 'contact_information',
        sortable: false
      }, {
        label: this.$t('settingsPatientsTable.contactInfo'),
        field: 'contact_information',
        sortable: false
      }]
      return addGenericCustomClasses(columns)
    }
  }
}

const AnalyticsRecordsData = {
  computed: {
    programOrLocationSelection () {
      return this.currentQuery.program_or_location_filter
    },
    hideMeasureItemLevelResponses () {
      return this.currentQuery.scorableFilter !== false
    },
    hideProgramFields () {
      return !(this.programsView) || this.programOrLocationSelection !== 'programFilter'
    },
    hideLocationFields () {
      return !(this.myPatientsView || this.appointmentView) || this.programOrLocationSelection !== 'locationFilter'
    },
    firstColumns () {
      return [{
        label: this.$t('analyticsRecordsData.completion_date'),
        field: 'completed',
        width: '130px'
      },
      {
        label: this.$t('analyticsRecordsData.completedBy'),
        field: 'respondent_owl_id',
        width: '140px'
      },
      {
        label: this.$t('analyticsRecordsData.record_id'),
        field: 'response_uuid',
        width: '110px'
      },
      {
        label: this.$t('analyticsRecordsData.due_date'),
        field: 'due_date',
        defaultHidden: false,
        width: '110px'
      },
      {
        label: this.$t('analyticsRecordsData.episodeId'),
        field: 'episode_id',
        width: '110px'
      },
      {
        label: this.$t('analyticsRecordsData.measure_name'),
        field: 'measure_abbreviation'
      }, {
        label: this.$t('analyticsRecordsData.measure_id'),
        field: 'measure_id',
        defaultHidden: false,
        width: '110px'
      },
      {
        label: this.$t('analyticsRecordsData.score_type'),
        field: 'score_type',
        defaultHidden: false,
        width: '200px'
      },
      {
        label: this.$t('analyticsRecordsData.score'),
        field: 'score',
        width: '60px'
      },
      {
        label: this.$t('analyticsRecordsData.measureQuestionCount'),
        field: 'measureQuestionCount',
        width: '60px',
        hide: this.hideMeasureItemLevelResponses
      }]
    },
    restOfColumns () {
      /**
       *  Length of treatment is displayed using different values depending on setting state
       *  So we set it up here as a conditional array, and then spread it into a larger array
       *  where we want it to appear.
       **/
      const lotColumn = []

      if (this.programOrLocationSelection === 'locationFilter') {
        lotColumn.push({
          label: this.$t('analyticsRecordsData.lot'),
          field: 'length_of_treatment_outpatient',
          width: '135px'
        }, {
          label: this.$t('analyticsRecordsData.taken_at'),
          field: 'response_type',
          width: '110px'
        })
      } else {
        lotColumn.push({
          label: this.$t('analyticsRecordsData.lot'),
          field: 'length_of_treatment',
          width: '135px'
        }, {
          label: this.$t('analyticsRecordsData.lotAtDischarge'),
          field: 'length_of_treatment_at_discharge',
          width: '130px'
        },
        {
          label: this.$t('analyticsRecordsData.taken_at_program'),
          field: 'response_type',
          width: '110px'
        },
        {
          label: this.$t('analyticsRecordsData.taken_at_episode'),
          field: 'episode_id_response_type',
          width: '110px'
        })
      }

      return [{
        label: this.$t('analyticsRecordsData.mrn'),
        field: 'mrn',
        width: '110px'
      }, {
        label: this.$t('analyticsRecordsData.first_name'),
        field: 'first_name',
        width: '110px'
      }, {
        label: this.$t('analyticsRecordsData.last_name'),
        field: 'last_name',
        width: '110px'
      }, {
        label: this.$t('analyticsRecordsData.gender'),
        field: 'gender',
        width: '80px'
      }, {
        label: this.$t('analyticsRecordsData.age'),
        field: 'age',
        width: '50px'
      }, {
        label: this.$t('analyticsRecordsData.dob'),
        field: 'birthdate',
        defaultHidden: false,
        width: '110px'
      }, {
        label: this.$t('analyticsRecordsData.location_name'),
        field: 'location_name',
        hide: this.hideLocationFields
      }, {
        label: this.$t('analyticsRecordsData.program_name'),
        field: 'program',
        hide: this.hideProgramFields
      }, {
        label: this.$t('analyticsRecordsData.program_id'),
        field: 'program_id',
        defaultHidden: false,
        hide: this.hideProgramFields,
        width: '110px'
      }, {
        label: this.$t('analyticsRecordsData.program_status'),
        field: 'program_status',
        defaultHidden: false,
        hide: this.hideProgramFields,
        width: '110px'
      }, {
        label: this.$t('analyticsRecordsData.admission_date'),
        field: 'program_admission',
        hidden: this.hideProgramFields,
        width: '110px'
      }, {
        label: this.$t('analyticsRecordsData.discharge_date'),
        field: 'program_discharge',
        width: '110px'
      }, {
        label: this.$t('analyticsRecordsData.clinical_team'),
        field: 'clinical_teams'
      }, {
        label: this.$t('analyticsRecordsData.clinician'),
        field: 'clinicians',
        defaultHidden: false
      },
      ...lotColumn,
      {
        label: this.$t('analyticsRecordsData.completed_on_due_date'),
        field: 'completed_on_due_date',
        defaultHidden: false,
        width: '110px'
      },
      {
        label: this.$t('analyticsRecordsData.primary_insurance_provider'),
        field: 'primary_insurance_provider',
        defaultHidden: false,
        width: '140px'
      },
      {
        label: this.$t('analyticsRecordsData.primary_member_id'),
        field: 'primary_member_id',
        defaultHidden: false,
        width: '110px'
      },
      {
        label: this.$t('analyticsRecordsData.primary_group_id'),
        field: 'primary_group_id',
        defaultHidden: false,
        width: '110px'
      },
      {
        label: this.$t('analyticsRecordsData.secondary_insurance_provider'),
        field: 'secondary_insurance_provider',
        defaultHidden: false,
        width: '140px'
      },
      {
        label: this.$t('analyticsRecordsData.secondary_member_id'),
        field: 'secondary_member_id',
        defaultHidden: false,
        width: '110px'
      },
      {
        label: this.$t('analyticsRecordsData.secondary_group_id'),
        field: 'secondary_group_id',
        defaultHidden: false,
        width: '110px'
      }]
    },
    measureQuestionColumns () {
      if (!this.hideMeasureItemLevelResponses && this.recordsData && this.recordsData.length && this.recordsData[0].questions) {
        return this.recordsData[0].questions.map((q, i) => ({
          label: q.library_text,
          field: 'questions',
          sortable: false,
          thClass: 'measure-question-tiem',
          tdClass: 'measure-question-tiem',
          isMeasureQuestionItem: true,
          itemIndex: i
        }))
      }
      return []
    },
    columns () {
      return [].concat(this.firstColumns, this.measureQuestionColumns, this.restOfColumns)
    }
  }
}
module.exports = {
  PatientsList,
  RespondentList,
  ClinicianAlertTable,
  CliniciansList,
  MeasuresClinician,
  ClinicianPatientProgram,
  ClinicianPatientProgramsTable,
  ClinicianSchedulesListSingleDay,
  ClinicianPrograms,
  PatientsByProgramTable,
  SettingsLocations,
  SettingsClinicalUsers,
  SettingsPatientUsers,
  ClinicianPatientMeasuresList,
  CliniciansTeamList,
  AnalyticsRecordsData,
  PatientsByDueDateColumns
}
